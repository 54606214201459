<template>
  <div class="contents liveInfo">
    <div class="flexB">
      <div class="tabBox one sdUpload" >
      <div class="flexL" style="width: 100%;">
        <div class="flexL" style="width: 100%;">
          <p class="bold" style="width: 132px;">{{ $t("mission-data-way-title") }}</p>
          <input style="width: 755px; margin-right: 8px;"
              type="text"
              v-model="title"
          />
          <button class="medium point" @click="onSampleDownload" style="width: 150px; padding-left: 0px; padding-right: 0px; text-align: center;">{{$t("mission-data-way-template-download")}}</button>
        </div>
        <p class="bold" style="width: 132px; padding: 6px 0px;">{{ $t("mission-data-way-file") }}</p>
        <div class="filebox">
          <input class="upload-name" v-model="fileName" disabled="disabled" />
          <label for="ex_file" style="width: 150px; font-weight: bold; font-size: 1.2rem; padding: 6px;">{{ $t("btn-find") }}</label>
          <input type="file" id="ex_file" ref="videos" @change="sendFile($event)" />
        </div>
        <div class="flexL" style="width: 100%; margin: 16px 0px;">
          <p class="bold" style="width: 132px;">{{ $t("mission-data-way-regdate") }}</p>
          <p>{{ moment(registerDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
        </div>
        <div class="flexL" style="width: 100%;">
          <p class="bold" style="width: 132px;">{{ $t("mission-data-way-registrant") }}</p>
          <p>{{ name }}</p>
        </div>
        <!-- <button class="small margin6" @click="upload">업로드</button> -->
      </div>


      <div class="error" v-if="uploadFail">
        <p>{{ $t("590") }}</p>
      </div>

      <div class="buttonWrap">
        <div>
          <button class="medium point" @click="upload" style="width: 120px;">{{ $t("btn-confirm") }}</button>
          <button class="medium margin6" @click="$router.go(-1)" style="width: 120px;">
            {{ $t("btn-cancel") }}
          </button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { fetchWaypointUpload, fetchWaypointTemplateDownloadUrl } from "@/api/missionWaypoint";
export default {
  props: ["eventId"],
  data() {
    return {
      moment: moment,
      registerDate: new Date(),
      uploadFail: false,
      fileSrc: null,
      title : null,
      fileName: "Select file..",
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 11 });
  },
  computed: {
    ...mapState({
      name: state => state.store.name,
    }),
  },
  methods: {
    sendFile(event) {
      this.file = event.target.files[0];

      this.fileName = this.file.name;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.fileSrc = e.target.result;
      };
    },
    onSampleDownload(){
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function(){
        if (this.readyState == 4 && this.status == 200){

          var filename = "";
          var disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
          }

          //this.response is what you're looking for
          var a = document.createElement("a");
          var url = URL.createObjectURL(this.response)
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
      xhr.open('GET', fetchWaypointTemplateDownloadUrl());
      xhr.responseType = 'blob'; // !!필수!!
      xhr.setRequestHeader("Access-Control-Request-Headers", "Content-Description")
      xhr.send(); // 파라미터 설정하는 부분이며 formData 설정 부분은 생략
    },

    upload() {
      if (this.fileSrc == null) {
        return alert(this.$t("alert-message-file-upload-check"));
      }

      if (this.title == null || this.title.trim() == "") {
        return alert(this.$t("alert-message-check-mission-title"));
      }

      let formData = new FormData();
      formData.append("file", this.file)
      formData.append("eventId", this.eventId)
      formData.append("title",this.title)

      fetchWaypointUpload(formData).then((res) => {
        if (res.data.result == 0) {
          alert(this.$t("alert-message-data-save"));
          this.$router.go(-1)
        } else {
          alert(res.data.message);
        }
      }).catch((e) => {
        console.error(e);
      });
    },
  },
};
</script>
